import { createRouter, createWebHashHistory } from "vue-router";
import AboutView from "../views/AboutView.vue";
import DealersView from "../views/DealersView.vue";
import BootsView from "../views/BootsView.vue";
import VintageView from "../views/VintageView.vue";
import RouchetteView from "../views/RouchetteView.vue";
import BoatshoesView from "../views/BoatshoesView.vue";
import Boatshoes2View from "../views/Boatshoes2View.vue";
import SomertonView from "../views/SomertonView.vue";
import DocksView from "../views/DocksView.vue";

const routes = [
  {
    path: "/",
    redirect: "/boots",
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/boots",
    name: "boots",
    component: BootsView,
  },
  {
    path: "/vintage",
    name: "vintage",
    component: VintageView,
  },
  {
    path: "/rouchette",
    name: "rouchette",
    component: RouchetteView,
  },
  {
    path: "/boatshoes",
    name: "boatshoes",
    component: BoatshoesView,
  },
  {
    path: "/boatshoes2",
    name: "boatshoes2",
    component: Boatshoes2View,
  },
  {
    path: "/somerton",
    name: "somerton",
    component: SomertonView,
  },
  {
    path: "/docks",
    name: "docks",
    component: DocksView,
  },
  {
    path: "/dealers",
    name: "dealers",
    component: DealersView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
