<template>
  <BookletViewer asset_path="booklets/boots/" :list_of_pages="pages" />
</template>

<script>
import BookletViewer from "../components/BookletViewer.vue";
export default {
  components: {
    BookletViewer,
  },
  data: function () {
    return {
      pages: [
        ["front1.png"],
        ["front2.png"],
        [
          {
            name: "S101 Bruton",
            color: "colors.brown",
            height: 35,
            size_min: 37,
            size_max: 46,
            price: 229,
            description: "shoes.boots.s101",
          },
          "s101.png",
        ],
        [
          {
            name: "S102 Bruton",
            color: "colors.green",
            height: 40,
            size_min: 37,
            size_max: 42,
            price: 249,
            description: "shoes.boots.s102",
          },
          "s102.png",
        ],
        [
          {
            name: "S103 Bruton Buckle",
            color: "colors.brown",
            height: 35,
            size_min: 41,
            size_max: 46,
            price: 259,
            description: "shoes.boots.s103",
          },
          "s103.png",
        ],
        [
          {
            name: "S104 Bruton",
            color: "colors.cognac",
            height: 35,
            size_min: 37,
            size_max: 42,
            price: 229,
            description: "shoes.boots.s104",
          },
          "s104.png",
        ],
        [
          {
            name: "S105 Bruton Buckle low",
            color: "colors.light-brown",
            height: 25,
            size_min: 37,
            size_max: 42,
            price: 229,
            description: "shoes.boots.s105",
          },
          "s105.png",
        ],
        [
          {
            name: "S202 Frome",
            color: "colors.brown",
            height: 40,
            size_min: 37,
            size_max: 46,
            price: 239,
            description: "shoes.boots.s202",
          },
          "s202.png",
        ],
        [
          {
            name: "S302 Maere",
            color: "colors.brown",
            height: 45,
            size_min: 37,
            size_max: 46,
            price: 349,
            description: "shoes.boots.s302",
          },
          "s302.png",
        ],
        [
          {
            name: "S403 Taunton",
            color: "colors.brown",
            height: 40,
            size_min: 37,
            size_max: 46,
            price: 269,
            description: "shoes.boots.s403",
          },
          "s403.png",
        ],
        [
          {
            name: "S404 Taunton",
            color: "colors.brown",
            height: 40,
            size_min: 37,
            size_max: 42,
            price: 289,
            description: "shoes.boots.s404",
          },
          "s404.png",
        ],
        [
          {
            name: "S503 Wells Wool",
            color: "colors.light-brown",
            height: 25,
            size_min: 37,
            size_max: 42,
            price: 229,
            description: "shoes.boots.s503",
          },
          "s503.png",
        ],
        [
          {
            name: "S504 Wells Wool",
            color: "colors.brown",
            height: 28,
            size_min: 41,
            size_max: 46,
            price: 249,
            description: "shoes.boots.s504",
          },
          "S504_Wells_Wool_Brown.jpg",
        ],
        [
          {
            name: "S601 Weston",
            color: "colors.brown-navy",
            height: 30,
            size_min: 37,
            size_max: 42,
            price: 219,
            description: "shoes.boots.s601",
          },
          "s601.png",
        ],
        [
          {
            name: "S602 Weston Extra Light",
            color: "colors.brown-blue",
            height: 35,
            size_min: 37,
            size_max: 43,
            price: 249,
            description: "shoes.boots.s602",
          },
          "s602.jpg",
        ],
        ["bedankkaart.png"],
        ["miniposter_boots.png"],
      ],
    };
  },
};
</script>
