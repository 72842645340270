<template>
  <BookletViewer asset_path="booklets/boots/" :list_of_pages="pages" />
</template>

<script>
import BookletViewer from "../components/BookletViewer.vue";
export default {
  components: {
    BookletViewer,
  },
  data: function () {
    return {
      pages: [
        [
          {
            name: "Vintage Lillehammer",
            color: "colors.brown",
            height: 30,
            size_min: 36,
            size_max: 47,
            price: 370,
            description: "shoes.boots.lillehammer",
          },
          "lillehammer.png",
        ],
        [
          {
            name: "Vintage Reykjavik",
            color: "colors.brown",
            height: 35,
            size_min: 36,
            size_max: 47,
            price: 390,
            description: "shoes.boots.reykjavik",
          },
          "reykjavik.png",
        ],
        ["bedankkaart.png"],
      ],
    };
  },
};
</script>
