<template>
  <div>
    <v-app-bar app :elevation="4" color="primary" height="100">
      <v-app-bar-nav-icon
        v-if="this.$vuetify.display.mobile"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-img
        :src="require('@/assets/logo.png')"
        height="100%"
        alt="logo"
      ></v-img>
      <div v-if="!this.$vuetify.display.mobile">
        <v-btn to="/about">{{ $t("menu.about") }}</v-btn>
        <v-btn>
          Outdoor boots
          <v-menu activator="parent" open-on-hover>
            <v-list bg-color="primary" density="compact" variant="plain">
              <v-list-item title="Sommerset Outdoor" to="/boots"></v-list-item>
              <v-list-item title="Vintage" to="/vintage"></v-list-item>
              <v-list-item
                title="Rouchette rubber boots"
                to="/rouchette"
              ></v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
        <v-btn>
          Boat shoes
          <v-menu activator="parent" open-on-hover>
            <v-list bg-color="primary" density="compact" variant="plain">
              <v-list-item title="Boat shoes" to="/boatshoes"></v-list-item>
              <v-list-item title="Loafers + docks" to="/docks"></v-list-item>
              <v-list-item title="Somerton" to="/somerton"></v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
        <v-btn to="/dealers">{{ $t("menu.dealers") }}</v-btn>
        <v-btn href="https://sommerset.myonline.store/">Webshop</v-btn>
      </div>
      <v-spacer v-if="!this.$vuetify.display.mobile"></v-spacer>
      <v-btn class="mr-4">
        {{ getLocaleButtonText() }}
        <v-menu activator="parent" open-on-hover>
          <v-list bg-color="secondary" density="compact" variant="text">
            <v-list-item @click="changeLocale('nl')">🇳🇱 NL</v-list-item>
            <v-list-item @click="changeLocale('en')">🇬🇧 EN</v-list-item>
            <v-list-item @click="changeLocale('de')">🇩🇪 DE</v-list-item>
            <v-list-item @click="changeLocale('fr')">🇫🇷 FR</v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </v-app-bar>
  </div>

  <v-navigation-drawer v-if="$vuetify.display.mobile" v-model="drawer" app>
    <v-list>
      <v-list-item to="/about">{{ $t("menu.about") }}</v-list-item>
      <v-list-group value="Outdoor boots">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Outdoor boots"></v-list-item>
        </template>
        <v-list-item title="Sommerset Outdoor" to="/boots"></v-list-item>
        <v-list-item title="Vintage" to="/vintage"></v-list-item>
        <v-list-item
          title="Rouchette rubber boots"
          to="/rouchette"
        ></v-list-item>
      </v-list-group>
      <v-list-group value="Boat shoes">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Boat shoes"></v-list-item>
        </template>
        <v-list-item title="Boat shoes" to="/boatshoes"></v-list-item>
        <v-list-item title="Loafers + docks" to="/docks"></v-list-item>
        <v-list-item title="Somerton" to="/somerton"></v-list-item>
      </v-list-group>
      <v-list-item to="/dealers">{{ $t("menu.dealers") }}</v-list-item>
      <v-list-item href="https://sommerset.myonline.store/">
        Webshop
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      drawer: false,
    };
  },
  props: {},
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    getLocaleButtonText() {
      if (this.$i18n.locale === "nl") {
        return "🇳🇱 NL";
      } else if (this.$i18n.locale === "en") {
        return "🇬🇧 EN";
      } else if (this.$i18n.locale === "de") {
        return "🇩🇪 DE";
      } else if (this.$i18n.locale === "fr") {
        return "🇫🇷 FR";
      }
    },
  },
};
</script>
