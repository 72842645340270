<template>
  <BookletViewer asset_path="booklets/boots/" :list_of_pages="pages" />
</template>

<script>
import BookletViewer from "../components/BookletViewer.vue";
export default {
  components: {
    BookletViewer,
  },
  data: function () {
    return {
      pages: [
        [
          {
            name: "ROUCH 1001 Veneur Neo Zip",
            color: "colors.brown",
            height: 42,
            size_min: 40,
            size_max: 46,
            price: "129.90",
            description: "shoes.boots.rouch1001",
          },
          "rouch1001.jpg",
        ],
        [
          {
            name: "ROUCH 1002 Grand Veneur",
            color: "colors.brown",
            height: 42,
            size_min: 40,
            size_max: 46,
            price: "124.90",
            description: "shoes.boots.rouch1002",
          },
          "rouch1002.jpg",
        ],
        [
          {
            name: "ROUCH 1003 Clean Garden",
            color: "colors.green-brown",
            height: 30,
            size_min: 40,
            size_max: 47,
            price: "67.90",
            description: "shoes.boots.rouch1003",
          },
          "rouch1003.jpg",
        ],
        [
          {
            name: "ROUCH 1004 Veneur Lady",
            color: "colors.green",
            height: 40,
            size_min: 36,
            size_max: 41,
            price: "89.90",
            description: "shoes.boots.rouch1004",
          },
          "rouch1004.jpg",
        ],
        [
          {
            name: "ROUCH 1005 Cities High",
            color: "colors.black",
            height: 40,
            size_min: 36,
            size_max: 41,
            price: "84.90",
            description: "shoes.boots.rouch1005",
          },
          "rouch1005.jpg",
        ],
        [
          {
            name: "ROUCH 1006 Cities Middel",
            color: "colors.black",
            height: 28,
            size_min: 36,
            size_max: 41,
            price: "74.90",
            description: "shoes.boots.rouch1006",
          },
          "rouch1006.jpg",
        ],
        [
          {
            name: "ROUCH 1007 Cities Low",
            color: "colors.black",
            height: 16,
            size_min: 36,
            size_max: 41,
            price: "59.90",
            description: "shoes.boots.rouch1007",
          },
          "rouch1007.jpg",
        ],
        ["bedankkaart.png"],
      ],
    };
  },
};
</script>
